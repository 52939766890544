import React, { useEffect, useState } from 'react'
import CountDownTimer from '../marketing/CountDownTimer'
import I18n from '../../helpers/I18n'
import { useDemoDayContext } from '../common/providers/DemoDayProvider'
import { useI18nContext } from '../common/providers/I18nProvider'
import isEmpty from 'lodash/isEmpty'

export const DEMO_DAY_BANNER_HEIGHT = 50
export const HIDE_DEMO_DAY_BANNER = {
  top: `-${DEMO_DAY_BANNER_HEIGHT}px`,
}
export const SHOW_DEMO_DAY_BANNER = {
  top: '0',
}
export const POSITION_NAV_DOWN = {
  top: `${DEMO_DAY_BANNER_HEIGHT}px`,
}
export const POSITION_NAV_UP = {
  top: '0',
}

export const IS_DASHBOARD = window.location.href.includes('/dashboard')

const DemoDayBanner = (): JSX.Element | undefined => {
  const demoDayContextValue = useDemoDayContext()

  const {
    isLivePeriod,
    isWatchOnDemandPeriod,
    demoDayBannerVisible,
    isAfterDemoDayBannerEndTime,
    demoDayStartTime,
  } = demoDayContextValue

  const { localizedPaths } = useI18nContext()

  const [displayLiveNow, setDisplayLiveNow] = useState(isLivePeriod)
  const [displayWatchOnDemand, setDisplayWatchOnDemand] = useState(isWatchOnDemandPeriod)
  const [scrollPosition, setScrollPosition] = useState(0)

  const currentYear = new Date().getFullYear()

  /**
   * Must be defined inside the component due to the IS_DASHBOARD const.
   * Invoking IS_DASHBOARD outside of the component returns an error.
   */
  const isDemoDayBannerVisible = !IS_DASHBOARD && demoDayBannerVisible

  const scrollListener = (): void => {
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    if (IS_DASHBOARD || isAfterDemoDayBannerEndTime) return
    window.addEventListener('scroll', scrollListener)

    if (isLivePeriod) {
      setDisplayLiveNow(true)
    }

    if (isWatchOnDemandPeriod) {
      setDisplayWatchOnDemand(true)
    }

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  if (isEmpty(demoDayContextValue)) return undefined

  const isLandingPage = () => window.location.pathname === '/'
  const enableLiveNow = () => setDisplayLiveNow(true)
  const reloadPage = () => location.reload()

  return (
    <a
      className='demo-day-banner'
      style={
        scrollPosition < DEMO_DAY_BANNER_HEIGHT && isDemoDayBannerVisible
          ? SHOW_DEMO_DAY_BANNER
          : HIDE_DEMO_DAY_BANNER
      }
      href={localizedPaths.demo_day}
      target={displayLiveNow ? undefined : '_blank'}
    >
      <div className='demo-day-banner-outer'>
        <div className='demo-day-banner-inner'>
          {displayLiveNow ? (
            <span className='demo-day-banner-live-now'>
              {I18n.t('views.banners.demo_day.during.left_text')}
            </span>
          ) : displayWatchOnDemand ? (
            <span className='demo-day-banner-live-now'>
              {I18n.t('views.banners.demo_day.post.left_text')}
            </span>
          ) : (
            demoDayStartTime && (
              <CountDownTimer
                eventStartTime={demoDayStartTime}
                showSeconds={true}
                callBack={isLandingPage() ? reloadPage : enableLiveNow}
              />
            )
          )}
          <div className='demo-day-banner-text-wrapper'>
            <span className='demo-day-banner-text'>
              {displayLiveNow
                ? I18n.t('views.banners.demo_day.during.main_text', { year: currentYear })
                : displayWatchOnDemand
                ? I18n.t('views.banners.demo_day.post.main_text', { year: currentYear })
                : I18n.t('views.banners.demo_day.main_text', { year: currentYear })}
            </span>
            <span>
              <span className='demo-day-banner-hashtag'>
                {/* Extra space to allow white-space wrapping */}{' '}
                {I18n.t('views.banners.demo_day.right_text', { year: currentYear })}
              </span>
              <span className='demo-day-banner-arrow-icon'></span>
            </span>
          </div>
        </div>
      </div>
    </a>
  )
}

export default DemoDayBanner
