import React, { useContext } from 'react'
import I18nJs from 'i18n-js'
import { LocalizedPaths } from '../../../types/Common'

type I18nType = typeof I18nJs

export const I18nContext = React.createContext<I18nContextProps>({} as I18nContextProps)

export const I18nConsumer = I18nContext.Consumer

export const useI18nContext = (): I18nContextProps => {
  const context = useContext(I18nContext)
  if (context === undefined) {
    throw new Error('useI18nProvider must be called within I18nContextProvider')
  }
  return context
}

type I18nContextProps = {
  I18n: I18nType
  localizedPaths: LocalizedPaths
}
interface Props {
  localizedPaths: LocalizedPaths
}

type State = {}

class I18nProvider extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <I18nContext.Provider
        value={{
          I18n: (window as any).I18n,
          localizedPaths: this.props.localizedPaths,
        }}
      >
        {this.props.children}
      </I18nContext.Provider>
    )
  }
}
export default I18nProvider
