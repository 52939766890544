import React from 'react'
import DemoDayBanner from '../../components/banners/DemoDayBanner'
import I18nProvider from '../../components/common/providers/I18nProvider'
import DemoDayProvider from '../../components/common/providers/DemoDayProvider'

const ErbDemoDayBanner = ({ localizedPaths, demoDayProps }) => {
  return (
    <I18nProvider localizedPaths={localizedPaths}>
      <DemoDayProvider {...demoDayProps}>
        <DemoDayBanner />
      </DemoDayProvider>
    </I18nProvider>
  )
}

export default ErbDemoDayBanner
