import React, { createContext, useContext } from 'react'

type DemoDayContextType = Props

const DemoDayContext = createContext<DemoDayContextType>({} as DemoDayContextType)
DemoDayContext.displayName = 'DemoDayContext'

export const DemoDayConsumer = DemoDayContext.Consumer

export interface Props {
  readonly demoDayBannerStartTime?: string
  readonly demoDayStartTime?: string
  readonly demoDayEndTime?: string
  readonly demoDayBannerEndTime?: string
  readonly isAfterDemoDayBannerStartTime?: boolean
  readonly isAfterDemoDayStartTime?: boolean
  readonly isAfterDemoDayEndTime?: boolean
  readonly isAfterDemoDayBannerEndTime?: boolean
  readonly isCountdownPeriod?: boolean
  readonly isLivePeriod?: boolean
  readonly isWatchOnDemandPeriod?: boolean
  readonly demoDayBannerVisible?: boolean
  readonly videoUrl?: string
}

const DemoDayProvider: React.FC<Props> = ({ children, ...props }) => {
  const contextValue = props

  return <DemoDayContext.Provider value={contextValue}>{children}</DemoDayContext.Provider>
}

export const useDemoDayContext = (): DemoDayContextType => {
  const context = useContext(DemoDayContext)
  if (!context) {
    throw new Error('useDemoDayContext must be used within a DemoDayProvider')
  }

  return context
}

export default DemoDayProvider
